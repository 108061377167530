.menu__mobile {
  display: block;

  button {
    padding: 0;
  }

  @media (--large) {
    display: none;
  }
}

.menu__trigger {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.0366em;
  user-select: none;
  @media (--large) {
    display: none;
  }
}

.menu__modal {
  position: fixed;
  inset: 0;
  top: calc(var(--header-height) + var(--snackbar-height));
  bottom: auto;
  background: var(--color-background);
  z-index: 100;
  max-height: calc(100svh - var(--header-height) - var(--snackbar-height));
  overflow-y: hidden;
  border: 0;
  border-bottom: 1px solid var(--color-black);

  @media (--large) {
    padding-top: 0.375rem;
    padding-bottom: 3rem;
  }
}

.accordion-content[data-state="open"] {
  border-top: none !important;
}

.accordion-item[data-state="open"] {
  border-top: 1px solid var(--color-black);
  &:first-child {
    border-top: none;
  }
}

.menu__content {
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.menu__close__bar {
  position: absolute;
  z-index: 101;
  top: 0;
  right: var(--page-inset);
}

.menu__close__icon {
  padding: 0.5rem 1rem;
  transform: translateX(1rem);
  user-select: none;
}

.menu__list {
  flex-grow: 1;
  & > .menu__accordion {
    display: flex;
    flex-direction: column;

    height: 100%;

    & h3 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.accordion-item:nth-child(1) .accordion-trigger {
  & > :nth-child(2) {
    display: none;
  }
}

.final__links {
  padding-block: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordion__link {
  @extend %typo-menu-link1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (--mobile-only) {
    text-transform: capitalize;
  }
}

.menu__accordion > .accordion__link > a {
  color: var(--link-color, inherit);
  @media (--mobile-only) {
    padding-block: 0.5rem;
  }
}

.sub__menu__accordion {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.change__country {
  width: 100%;
  align-items: center;
  padding-block: 4px;

  &[data-active="true"] {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: var(--color-primary);
    text-underline-offset: 2px;
  }
}

.menu__overlay {
  position: fixed;
  inset: 0;
  background: var(--color-primary);
  opacity: 0.2;
  z-index: 99;
  @media (--large) {
    display: none;
  }
}

.menuRoot {
  height: var(--header-height);

  & > div[data-state="open"] {
    width: calc(100vw) !important;
    transform: translateX(-1.75rem) !important;
  }
}

.menu__desktop {
  display: none;
  @media (--large) {
    display: block;
  }
}

.menuList {
  display: flex;
  height: var(--header-height);
  align-items: center;
  justify-content: space-between;
  width: min(90%, 25rem);
  padding: 0;

  & > li {
    list-style-type: none;
    height: 100%;
    display: flex;
    align-items: auto;

    &:first-child .menuButton {
      padding-left: 0;
    }
  }
}

.menuButton {
  @extend %typo-menu-link1;
  padding: 0 0.5rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  text-align: center;

  & > .underline {
    position: relative;
    top: 0.125rem;
    content: "&nbsp";
    width: 0;
    height: 1px;
    background-color: var(--color-black);
    transition: width 150ms ease-out;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;

    & > .underline {
      width: 100%;
    }
  }
}

.menuLink {
  color: var(--link-color, inherit);
  padding-top: 2px;
}

.menuContent {
  width: 100vw;
  padding: 1.5rem 2.5vw 2.5rem;
  margin: 0;
  display: flex;
  &[data-motion="from-start"] {
    animation: enterTop 250ms ease;
  }
  &[data-motion="from-end"] {
    animation: enterBottom 250ms ease;
  }
  &[data-motion="to-start"] {
    animation: exitTop 250ms ease;
  }
  &[data-motion="to-end"] {
    animation: exitBottom 250ms ease;
  }
}

.viewport {
  border-bottom: 1px solid var(--color-black);
  background: var(--color-background);
  z-index: -1;
  position: absolute;
  overflow: hidden;
  margin: 0 -2.5vw;
  width: 100%;
  height: var(--radix-navigation-menu-viewport-height);
  transition: height 250ms ease;

  &[data-state="open"] {
    animation: enterTop 250ms ease;
  }

  &[data-state="closed"] {
    animation: exitTop 250ms ease;
  }
}

@keyframes enterTop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes enterBottom {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes exitTop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes exitBottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.linkMenus {
  grid-template-columns:
    minmax(auto, max-content) minmax(auto, max-content) minmax(
      auto,
      max-content
    )
    minmax(auto, max-content) minmax(auto, max-content) minmax(
      auto,
      max-content
    )
    minmax(auto, max-content) minmax(auto, max-content) minmax(auto, max-content);
  flex-grow: 0;
  display: grid;
  column-gap: 2rem;
  width: 100%;
}

.linkMenuSection {
  grid-column: span 1;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: space-between;
  &.spanTwoColumns {
    grid-column: span 2;
  }
}

.linkMenuTitle {
  @extend %typo-menu-tag1;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}

.linkSection {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, auto);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 2rem;
  row-gap: 0.625rem;
}

.subMenuLink {
  @extend %typo-menu-link2;
  padding: 0;
  margin: 0;
}

.menu__button__link {
  list-style: none outside none;
}

.section__grid {
  width: min(100%, 15rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-block: 1rem;
  gap: 0.5rem;

  & > * {
    text-align: left;
  }

  @media (--mobile-only) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}
