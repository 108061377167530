.root {
  position: relative;
}

.trigger {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-inline: var(--page-inset);
  &[data-state="open"] .icon {
    transform: rotate(90deg);
  }
  & > span {
    @extend %typo-menu-link1;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: var(--page-inset);
  height: 100%;
  display: grid;
  align-items: center;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
