.wishlistButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 16px;
  right: 16px;

  width: 25px;
  height: 25px;
  padding-bottom: 1px;

  z-index: 2;
  border-radius: 100%;
  --svg-color: var(--color-black);

  :hover {
    opacity: 0.7;
  }

  &:hover,
  &__active {
    /* --svg-color: var(--color-white); */
    --heart-fill: var(--svg-color);
    & svg,
    & path {
      opacity: 1;
    }
  }
}
