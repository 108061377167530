.text-center {
  text-align: center;
}

.container {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: min-content;
  gap: 1rem;
}

.input {
  margin: 1rem auto;
  padding: 0 15px;
  max-width: 400px;
}
