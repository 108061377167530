.container {
  --container-width: calc(100%);
  display: flex;
  flex-direction: column;
  position: relative;
  width: var(--container-width);

  @media (--large) {
    gap: 3vw;
    flex-direction: row;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: min-content;

  @media (--large) {
    max-width: clamp(360px, 26.35vw, 720px);
    margin: 0 auto;
  }
}

.price {
  font-family: var(--font-haas-grot-display);
  font-size: 1.0833rem;
  letter-spacing: 0.10833rem;
  line-height: 1.25rem;
  font-weight: 400;
  @media (--mobile-only) {
    font-size: 0.8125rem;
  }
}

.title__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (--large) {
    margin-bottom: clamp(40px, 2.92vw, 80px);
  }
}

.product-description {
  @media (--large) {
    order: 0;
  }
  & > p {
    @extend %typo-b3;

    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }

    & a {
      font-weight: 600;
      text-decoration: none;
    }

    & u:has(a) {
      text-decoration: none;
      & a {
        &:after {
          display: none;
        }
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          top: auto;
          height: 1px;
          bottom: -2px;
          background-color: var(--color-primary);
          width: 100%;
          margin-inline: auto;
          transition: width 200ms ease;
          transition-delay: 75ms;
        }
        &:hover::before {
          width: 0;
        }
      }
    }
  }
}

.mobile__description {
  display: block;
  @media (--large) {
    display: none;
  }
}

.desktop__description {
  display: none;
  @media (--large) {
    display: block;
  }
}

.mobile__gallery {
  display: block;
  position: relative;

  @media (--large) {
    display: none;
  }
}

.desktop__gallery {
  display: none;
  position: relative;

  @media (--large) {
    display: block;
    width: calc(var(--container-width) - 34%);
  }
}

.title {
  @media (--mobile-only) {
    font-size: 1.375rem;
  }
}

.plp_top_bar {
  position: relative;
  & > button {
    height: 100%;
    top: 0;
    display: grid;
    place-items: center;
    @media (--large) {
      display: none;
    }
  }
}
