.container {
  --body-margin: 2vw;
  --body-margin-small: 1vw;
  --body-width: max(50%, 350px);
  --body-maxWidth: 80%;

  display: flex;
  flex-direction: column;
  width: var(--body-width);
  max-width: var(--body-maxWidth);
  &__center {
    margin-inline: auto;
  }

  &__left {
    margin-right: auto;
    margin-left: 10vw;
  }

  &__right {
    margin-left: auto;
    margin-right: 10vw;
  }

  & > * {
    margin-top: var(--body-margin);
  }

  & > p + p {
    margin-top: var(--body-margin-small);
  }

  & > h2,
  & > h3,
  & > h4 {
    & + p {
      margin-top: var(--body-margin-small);
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & a {
    display: inline-block;
  }

  p {
    line-height: 1.4375;
  }

  u {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--color-primary-80);
    text-decoration-thickness: 1px;
  }
}

.unordered__list {
  list-style: square;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}

.ordered__list {
  list-style: decimal;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}

/* Seperate styling for heading elements inside rich text */
.center {
  margin-inline: auto;
}

.left {
  text-align: left;
  margin-right: auto;
}

.right {
  text-align: right;
  margin-left: auto;
}
