.search-form {
  --transition: 200ms;
  --input-font-size: var(--font-size-s);
  --input-padding-v: 0.5rem;
  --input-padding-h: 0;
  --input-br: 0px;

  position: relative;
  margin-bottom: 6px;
  display: none;

  @media (--large) {
    --input-font-size: var(--font-size-xs);
    --input-padding-v: 0.5rem;
    --input-padding-h: 0;
  }

  &.active {
    display: block;
  }

  & > button > svg path {
    stroke-width: 1px;
    stroke: var(--color-primary);
    color: var(--color-primary);
  }

  & > input {
    background: inherit;
    box-sizing: border-box;
    padding: var(--input-padding-v) var(--input-padding-h);
    border: 0;
    border-bottom: 1px solid var(--color-primary);
    width: 100%;
    font-size: var(--input-font-size);
    transition: all var(--transition);
    line-height: var(--line-height);

    &:hover {
      &::placeholder {
        opacity: 0.6;
        transform: translateY(-2px);
      }
    }

    &::placeholder {
      /* Not currently in use */
      transition: all 0.1s ease;
      color: var(--color-primary);
      font-size: var(--font-size-xs);
      opacity: 1;
    }

    &:focus {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.02);
    }
    &:focus-visible {
      outline: 0;
    }

    &.active {
      padding: calc(var(--input-padding-v) + 4px) var(--input-padding-h)
        calc(var(--input-padding-v) - 4px);

      & + label {
        font-size: 10px;
        top: 10px;
        line-height: 1;
      }
    }
  }

  & label {
    position: absolute;
    left: var(--input-padding-h);
    top: var(--input-padding-v);
    font-size: var(--input-font-size);
    color: var(--color-primary-60);
    pointer-events: none;
    transition: all var(--transition);
    line-height: var(--line-height);
  }

  & > button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 var(--input-padding-h);
    background: transparent;
    border: none;
    border-radius: var(--input-br);
    cursor: pointer;
  }
}
