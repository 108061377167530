.denim__section {
  /*padding-block: var(--section-spacing);*/
  border-top: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-black);
  background: var(--color-white);

  & > *:first-child:not([data-blok-background][data-layout="full"]) {
    margin-top: var(--section-margin);
  }

  & > *:last-child:not([data-blok-background][data-layout="full"]) {
    margin-bottom: var(--section-margin);
  }

  padding-bottom: 3rem;
}

.denim__section:first-child {
  border-top: none;
}

.denim__section:last-child {
  border-bottom: none;
}
