.item {
  width: 100%;
  display: flex;
  gap: 2rem;
  position: relative;
  margin-bottom: 20px;

  & p {
    font-size: var(--font-size-fixed-2xs);
    font-family: var(--font-haas-grot-display);
    font-weight: 100;
    letter-spacing: 0.04em;
  }

  & .details__container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    padding-bottom: 2rem;
    padding-top: 0.25rem;
  }
}

.image {
  width: 27%;
  flex-shrink: 0;
}

.left__column {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .item__name {
    @extend %typo-menu-link1;
    font-weight: 800;
    padding-bottom: 0.75rem;
  }
}

.right__column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;

  & .regular__price {
    letter-spacing: 0.08em;
  }

  & .discounted__wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-content: flex-end;
    letter-spacing: 0.08em;
    text-align: end;

    & .discounted__price {
      opacity: 0.6;
      text-decoration: line-through;
      font-size: var(--font-size-fixed-2xs);
    }
  }

  & .quantity__wrapper {
    padding-bottom: 1rem;
  }

  & .remove__button {
    --remove-button-padding: 0.5rem;
    position: relative;
    padding: var(--remove-button-padding);
    padding-right: 0;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      left: var(--remove-button-padding);
      top: auto;
      bottom: var(--remove-button-padding);
      width: calc(100% - var(--remove-button-padding));
      height: 1px;
      background-color: var(--color-black);
      margin-inline: auto;
      transition: all 0.15s ease;
    }
    &:hover {
      &::after {
        width: 0;
      }
    }
  }
}

.move-button-span {
  --remove-button-padding: 0rem;
  position: relative;
  padding: var(--remove-button-padding);
  padding-right: 0;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    left: var(--remove-button-padding);
    top: auto;
    bottom: var(--remove-button-padding);
    width: calc(100% - var(--remove-button-padding));
    height: 1px;
    background-color: var(--color-black);
    margin-inline: auto;
    transition: all 0.15s ease;
  }
  &:hover {
    &::after {
      width: 0;
    }
  }
}

.move-button {
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
