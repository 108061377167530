.modal {
  --container-padding: 20px 36px 34px 24px;
  z-index: 920;
  height: fit-content;
  width: 90%;
  align-items: center;
  min-height: var(--modal-height);
  animation: fadeIn 0.6s;

  background-color: var(--color-background);
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: var(--container-padding);
  overflow: auto;

  @media (--large) {
    width: 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-center {
  text-align: center;
}

.close {
  align-self: end;
  font-size: 16px;
  @media (--large) {
    font-size: inherit;
  }
}

.ingress {
  margin-top: 24px;
}

.modal-button-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 0 5px;
  border-radius: 50px;
}

.policyText {
  font-size: var(--font-size-2xs);
  margin-top: 24px;
  @media (--large) {
    margin-top: 40px;
  }
}

.inputgroup {
  --input-gap: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--input-gap);

  @media (--small) {
    flex-wrap: unset;
  }
}

.formgroup {
  margin-top: 24px;
  @media (--large) {
    margin-top: 50px;
  }
}

.ingress,
.formgroup {
  width: 100%;

  @media (--small) {
    width: 85%;
  }
  & input,
  button {
    border: 1px solid transparent;
  }
}

.success {
  --text-spacing: 80px;
  margin-block: var(--text-spacing);
}

.errorMessage {
  color: red;
}

.input {
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 100px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 1.5em;
    --input-padding-h: 1em;
  }
  width: 100%;
  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;

  &:focus {
    border: 1px solid var(--color-black);
  }
  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-primary-70);
    font-size: var(--input-font-size);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }
}
