.container {
  padding: clamp(12px, 0.87vw, 24px) 0;

  & > a,
  span {
    font-size: var(--font-size-menu-item);
    letter-spacing: 0.08em;
    line-height: calc(15 / 16);
    color: var(--color-primary-80);
  }
  margin: 0 auto;
}

.small-container {
  padding: clamp(12px, 0.87vw, 24px) 0;
  display: flex;
  gap: 4px;
  & > a,
  span {
    font-size: var(--font-size-menu-item);
    letter-spacing: 0.08em;
    line-height: calc(15 / 16);
    color: var(--color-primary-80);
  }
}
