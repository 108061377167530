.form {
  width: 100%;
  min-height: 50px;
  margin-top: 1rem;
  @media (--large) {
    margin-top: 0;
  }
  &[data-state="success"] {
    & + * {
      opacity: 0;
    }
  }
}

.label {
  width: 100%;

  position: relative;
  display: block;

  /* animation */
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease;
  &[data-visible="true"] {
    max-height: 300px;
  }
}

.input {
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 100px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 1.5em;
    --input-padding-h: 1em;
  }
  width: 100%;
  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;

  &:focus {
    border: 1px solid var(--color-primary);
  }
  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-primary-70);
    font-size: var(--input-font-size);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }
}

.button__wrapper {
  position: absolute;
  inset: 0;
  left: auto;
  display: grid;
  place-content: center;
  right: 0px;
  height: 100%;
}

.button {
  border-radius: 100px;
  color: var(--color-primary);

  transition: all var(--transition);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 0px;
  padding: 14px 10px 18px 10px;
  &:hover {
    cursor: pointer;
    background: var(--color-primary-80);
    color: var(--color-white);
  }
  &:focus {
    border: 1px solid var(--color-primary);
  }
  &:focus-visible {
    outline: 0;
  }
}

.success {
  max-height: 0px;
  color: var(--color-primary);
  overflow: hidden;
  transition: all 0.2s ease;
  &[data-visible="true"] {
    max-height: 30px;
  }
}

.error {
  color: var(--color-red-dark);
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease;
  &[data-visible="true"] {
    max-height: 30px;
  }
}
