.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 60svh;
  padding-block: 10vh;
  margin-bottom: 4rem;

  color: var(--color-white);

  @media (--large) {
    margin-bottom: var(--spacing-l);
  }
}

.image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  & * {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.hero__title {
  white-space: pre;
  text-align: center;
}

.articles__grid {
  display: grid;
  margin: 0 var(--page-inset);

  grid-template-columns: repeat(auto-fit, minmax(1fr, 100%));
  grid-gap: 24px;
  row-gap: 80px;

  @media (--large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.articles__categories {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-s);
  letter-spacing: 0.04em;
  justify-content: center;

  margin-bottom: var(--spacing-l);
  gap: 20px;
  @media (--large) {
    margin: 0;
  }
}

.articles__topbar {
  display: grid;

  grid-template-columns: 1fr;
  align-items: center;
  margin: auto var(--page-inset);
  margin-top: 4rem;

  @media (--large) {
    margin-top: var(--section-margin);
    grid-template-columns: 1fr auto 1fr;
    margin-bottom: 30px;
  }

  & > * {
    &:first-child {
      display: none;
    }
  }

  @media (--large) {
    & > * {
      &:first-child {
        display: block;
      }
    }
  }
}

.active {
  text-decoration: underline;
  text-underline-offset: 4px;
  color: var(--color-primary-80);
}
