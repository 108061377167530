.root {
  max-width: 100vw;
  overflow-x: scroll;
  container-name: mediaGallery;
  container-type: inline-size;
  @media (--large) {
    overflow: unset;
  }
}

.grid {
  display: inline-flex;
  gap: 2rem;
  flex-direction: column;
  margin: 1rem;
  width: calc(100% - 2rem);
  @media (--large) {
    width: auto;
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
    margin: 0 var(--page-inset);
    & {
      @container (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        & > *:nth-child(2n) {
          transform: translateY(50%);
        }
      }
    }
  }
}

.item {
  width: 100%;

  @media (--large) {
    width: auto;
  }

  & .link {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      top: auto;
      bottom: -3px;
      width: 0%;
      height: 1px;
      background-color: var(--color-primary);
      margin-inline: auto;
      transition: all 0.15s ease-in-out;
    }
  }
  &:hover .link {
    &:after {
      width: 100%;
    }
  }
}

.item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  gap: 0.5rem;
}

.title-container {
  border-top: 1px solid var(--color-primary);
  padding-top: 1rem;
  display: grid;

  align-items: flex-start;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 1rem var(--page-inset);
  grid-template-columns: 1fr auto 1fr;

  @media (--large) {
    padding-top: 2rem;
    margin: 2rem var(--page-inset);
  }
}
