.accordion {
  border-bottom: 1px solid #bcb8af;

  &:first-child {
    border-top: 1px solid #bcb8af;
  }
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--color-hover);
  }

  & > button {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  & > div[data-state="open"] {
    padding-bottom: 1rem;
  }
}

.accordion-group {
  margin-top: clamp(40px, 2.92vw, 80px);
  padding-bottom: 4vw;

  & p {
    font-family: var(--font-haas-grot-display);
    letter-spacing: 0.04em;
    line-height: 1.3;
    font-size: var(--font-size-2xs);
  }

  & p:has(+ *) {
    margin-bottom: 1rem;
  }

  & ol {
    & li {
      line-height: 1.4;
      font-size: var(--font-size-2xs);
    }
  }
}

.accordion-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  text-align: left;
  width: 100%;
  padding: 0;
  padding-top: 10px;
  text-transform: uppercase;
  font-family: var(--font-century-gothic);
  font-weight: 400;
  letter-spacing: 0.08em;
  &:hover {
    color: var(--color-hover);
    cursor: pointer;
  }
}
