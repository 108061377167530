.quantity-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  font-size: var(--font-size-2xs);
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1;
  gap: var(--gap);
  background-color: white;
  color: var(--violet11);
  box-shadow: 0 2px 10px hsl(0deg 0% 0% / 3%);

  & > button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    padding: 0;
    font-size: var(--font-size-2xs);
  }
  & > span > p {
    font-size: var(--font-size-2xs);
    margin: 0;
    padding: 0;
  }
}
.select-trigger:hover {
  background-color: var(--mauve3);
}
.select-trigger:focus {
  box-shadow: 0 0 0 2px black;
}
