.heading {
  font-size: var(--font-size-l);
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 16px;
}

.form__heading {
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 1.5;
  padding-top: 16px;
  padding-bottom: 12px;
}

.form__group {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  max-width: 100%;
  width: 550px;
}

.form__field {
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 12px;
  --input-padding-h: 20px;
  --input-br: 56px;
  @media (--large) {
    --input-font-size: var(--font-size-xs);
    --input-padding-v: 12px;
    --input-padding-h: 24px;
  }

  position: relative;

  & > input {
    --transition: 200ms;
    --input-font-size: var(--font-size-xs);
    --input-padding-v: 1.5rem;
    --input-padding-h: 1rem;
    --input-br: 100px;
    @media (--large) {
      --input-font-size: var(--font-size-fixed-2xs);
      --input-padding-v: 2rem;
      --input-padding-h: 1.25rem;
    }

    width: 100%;
    padding-inline: var(--input-padding-v);
    padding-block: var(--input-padding-h);
    font-size: var(--input-font-size);
    background: var(--color-primary-20);
    border-radius: var(--input-br);
    border: 1px solid var(--color-background);
    &:focus {
      border: 1px solid var(--color-primary);
    }
    &:focus-visible {
      outline: 0;
    }
    &::placeholder {
      color: var(--color-primary);
      font-size: var(--input-font-size);
    }
    &:hover::placeholder {
      opacity: 0.6;
    }
  }
  &:hover {
    & input {
      border: 1px solid var(--color-primary);
    }
  }
  &:has(> span[data-error]) {
    margin-bottom: 2.5ch;
  }
}
.Label {
  color: var(--color-primary-80);
  font-size: var(--font-size-fixed-xs);
  line-height: var(--line-height);
  transition: all var(--transition);
  position: absolute;
  left: calc(var(--input-padding-h) + 0.55rem);
  top: 0;
  bottom: 0;
  height: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: auto auto;

  &.required {
    &:after {
      content: "*";
      color: var(--color-red-dark);
      margin-left: 2px;
    }
  }
  &.active {
    font-size: 10px;
    line-height: 1;
    height: 40%;
  }
}

.error {
  color: var(--color-red);
  font-size: var(--font-size-fixed-2xs);
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  bottom: -23px;
  left: 0;
  animation: appear-from-top 0.2s ease-in;
}

@keyframes appear-from-top {
  from {
    transform: translateY(-8px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
