.navWrapper {
  height: var(--header-height);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.nav {
  height: 100%;
  --nav-spacing: 0.75rem;
  background: var(--color-background);
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  box-sizing: border-box;
  padding: 0 var(--page-inset);
  border-bottom: 1px solid var(--color-black);
  z-index: 2;

  @media (--large) {
    --nav-spacing: 2.25rem;
    padding: 0 3.5rem;
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  gap: var(--nav-spacing);
}

.right button {
  p {
    margin-left: 0;
  }

  @media (--large) {
    p {
      margin-left: initial;
    }
  }

  @extend %typo-menu-link1;
}

.logo {
  width: min(177px, 35vw);
}

.logo svg {
  width: 100%;
  min-height: fit-content;
  height: auto;
}

.location__selector__desktop {
  display: none;
  @media (--large) {
    display: block;
  }
}

.snackbarOuter {
  width: 100%;
  color: #fff;
  background: #000;
  text-align: center;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  & a,
  & span {
    color: #fff;
    font-family: var(--font-haas-grot-display);
    font-size: var(--font-size-menu-item);
    font-weight: 300;
    letter-spacing: var(--body-ls-fixed);
    padding: 6px;
    display: inline-block;
  }
}

.snackbarItem {
  display: flex;
  transition: all 600ms;
  transform: translateY(20px) translateX(0);
  opacity: 0;
  position: absolute;
  justify-content: center;
  gap: 10px;
  text-align: center;
  pointer-events: none;

  &.active {
    transform: translateY(0) translateX(0);
    opacity: 1;
    pointer-events: all;
  }

  &.prev {
    transform: translateY(-20px) translateX(0);
  }

  & span {
    color: white;
  }

  & a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
