.articles__grid {
  display: grid;
  margin: 0 var(--page-inset);
  grid-template-columns: repeat(auto-fit, minmax(1fr, 100%));
  grid-gap: 24px;
  row-gap: 80px;

  @media (--large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
