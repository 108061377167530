.button {
  --button-padding-h: clamp(23px, 1.68vw, 46px);
  --button-padding-v: clamp(10px, 0.73vw, 20px);
  --button-fs: clamp(0.625rem, 0.878vw, 0.85rem);

  transition: all ease 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  letter-spacing: 0.12em;
  font-size: var(--button-fs);

  &.medium {
    --button-fs: clamp(0.75rem, 0.878vw, 0.875rem);
  }
  &.large {
    --button-fs: clamp(0.875rem, 0.878vw, 0.925rem);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-primary-40);
    box-shadow: 0px 0px 10px rgba(215, 214, 209, 0.5);
    border: none;
    color: var(--color-background);
    &:hover {
      border: none;
      background-color: var(--color-primary-40);
      color: var(--color-background);
    }
  }
}

.white {
  background-color: var(--color-background);
  color: var(--color-primary);
  &:hover {
    background-color: var(--color-background);
    color: var(--color-background);
    & svg path {
      stroke: var(--color-background);
      fill: var(--color-background);
    }
  }
  & svg path {
    stroke: var(--color-primary);
    fill: var(--color-primary);
  }
}

.gray {
  background-color: var(--color-primary-40);
  color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-background);
    & svg path {
      stroke: var(--color-background);
      fill: var(--color-background);
    }
  }
  & svg path {
    stroke: var(--color-primary);
    fill: var(--color-primary);
  }
}

.beige {
  background-color: var(--color-button-beige);
  color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-background);
    & svg path {
      stroke: var(--color-background);
      fill: var(--color-background);
    }
  }
  & svg path {
    stroke: var(--color-primary);
    fill: var(--color-primary);
  }
}

.transparent {
  background-color: transparent;
  color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-background);
    & svg path {
      stroke: var(--color-background);
      fill: var(--color-background);
    }
  }
  & svg path {
    stroke: var(--color-primary);
    fill: var(--color-primary);
  }
}

.black {
  background-color: var(--color-primary);
  color: var(--color-background);
  border: 1px solid var(--color-primary);
  box-sizing: border-box;
  &:hover {
    box-sizing: border-box;
    background-color: var(--color-background);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    & svg path {
      stroke: var(--color-primary);
      fill: var(--color-primary);
    }
  }
  & svg path {
    stroke: var(--color-background) !important;
    fill: var(--color-background) !important;
  }
}

.outlined {
  border-width: 1px;
  border-style: solid;
}

.not-outlined {
  border: none;
}

.rounded {
  border-radius: 100%;
  padding: 8px;
  aspect-ratio: 1;
  padding: 0;
  width: 48px;
  position: relative;
  & svg {
    position: absolute;
    inset: 0;
    margin: auto;
  }
}

/* not rounded & not wideAndNarrow */
.standard {
  --button-br: 50px;
  border-radius: var(--button-br);
  padding: var(--button-padding-v) var(--button-padding-h);
  text-decoration: none;
}

.wideAndNarrow {
  --button-br: 50px;
  border-radius: var(--button-br);
  padding: 8px 38px !important;
  text-decoration: none;
}

.grow {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  box-shadow: 0px 0px 10px rgba(215, 214, 209, 0.5);
}

.whiteText {
  color: var(--color-background);
  border-color: var(--color-background);

  &.transparent {
    &:hover {
      background-color: var(--color-background);
      color: var(--color-primary);
      & svg path {
        stroke: var(--color-primary);
        fill: var(--color-primary);
      }
    }
  }
}

.blackText {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.shadow {
  box-shadow: 0px 0px 10px #67645f80;
}
