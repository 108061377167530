.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--page-inset-width);
  margin-inline: auto;
  margin-bottom: var(--spacing-l);
}

.hero__wrapper {
  padding-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__title {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  white-space: pre-wrap;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
}

.hero__preambles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  font-size: var(--font-size-3xs);
  width: 100%;
  @media (--mobile-only) {
    & * {
      &:first-child {
        order: 2;
      }
    }
  }
  @media (--large) {
    flex-direction: row;
    place-items: flex-start;
    justify-content: space-between;
  }
  & > *:nth-child(2) {
    place-self: center;
  }
  & > *:last-child {
    place-self: end;
  }
}

.image {
  min-height: max(33vw, 50vh);
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  aspect-ratio: var(--mobile-image-aspect-ratio, 1 / 1);
  @media (--large) {
    aspect-ratio: var(--desktop-image-aspect-ratio, 16 / 9);
  }
  & * {
    position: absolute;
    inset: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}

.breadcrumbs__wrapper {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.breadcrumbs {
  max-width: unset !important;
  border-bottom: 1px solid var(--color-primary);
  &:first-child {
    color: var(--color-primary-60);
    padding-left: 2rem;
  }
  & * {
    text-transform: capitalize;
  }
}
