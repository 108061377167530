/* FILEPATH: /Users/filipjohansen/frend-fulfilment/apps/camilla-pihl/src/components/atoms/SizeGuide/size-guide.module.css */

.size-guide {
  overflow: hidden;
}

.table {
  --divider-color: var(--color-primary-50);
  --border-radius: 0rem;
  --background: transparent;
  border-radius: var(--border-radius);
  border-collapse: collapse;
  background-color: var(--background);
  width: 100%;
  overflow: hidden;
  cursor: auto;
  &::first-child {
    border-left: none;
  }
  &::last-child {
    border-right: none;
  }

  /* default type */
  font-family: var(--font-haas-grot-display);
  font-size: var(--font-size-2xs);
  letter-spacing: 0.04em;
  line-height: 1.3;
}

.heading {
  padding: 0.75rem;
  text-align: center;
  border-right: 1px solid var(--divider-color);
  &:last-of-type {
    border-right: none;
  }

  /* heading type */
  font-weight: 400;
  font-size: var(--font-size-fixed-2xs);
  font-family: var(--font-century-gothic);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.td {
  border: 1px solid var(--divider-color);
  padding: 0.5rem;
  text-align: center;
  &:last-child {
    border-right: none;
  }
  width: 21.25%;
  &:first-child {
    width: 12.5%;
  }
}

.size {
  border: 1px solid var(--divider-color);
  text-align: center;
  border-left: none;
}

.row {
  border: none;

  &:last-child > td {
    border-bottom: none;
  }
}
