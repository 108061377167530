.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: min(75%, 50vw);
  margin: auto;
  gap: 2rem;

  &.extra__wide {
    max-width: min(calc(100% - 4rem), 75vw);
  }
}
