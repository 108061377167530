.depictWrapper {
  width: 100%;
  min-width: 100%;
}

.root {
  display: none;
  @media (--large) {
    display: block;
  }
}

.card__container {
  container-type: inline-size;
  container-name: product-card;
  position: relative;
  min-width: 100%;
}

.product-card {
  --distance-from-bottom: 7%;
  position: relative;
  display: block;
  isolation: isolate;

  @media (--small) {
    &:hover .pc-button {
      opacity: 1;
    }

    &:has(.pc-button:hover) .product-image {
      &.first {
        opacity: 0;
      }
      &.second {
        opacity: 1;
      }
    }
    &:has(.action:hover) .product-image {
      &.first {
        opacity: 0;
      }
      &.second {
        opacity: 1;
      }
    }

    &:has(.pc-button:hover) .action {
      display: flex;
      opacity: 1;
    }
    &:has(.action:hover) .pc-button {
      opacity: 0;
    }
  }
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
  bottom: auto;
  transition: var(--fade-transition);
  transition-delay: 0.02s;

  &.first {
    opacity: 1;
  }
  &.second {
    opacity: 0;
  }
  @media (--small) {
    &:hover {
      &.first {
        opacity: 0;
      }
      &.second {
        opacity: 1;
      }
    }
  }
}

.product-description {
  display: flex;
  inset: 0;
  top: auto;
  bottom: -16px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  margin-top: clamp(8px, 0.585vw, 16px);
  @extend %typo-b2;
  letter-spacing: 0.48px;
  font-size: var(--font-size-2xs);
  @media (--small) {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    padding-left: 0.25rem;
    padding-right: 0.05rem;
    gap: 0;
    bottom: 0;
  }
  @container product-card (max-width: 300px) {
    display: flex;
    padding: 0;
    gap: 4px;
    bottom: -16px;
  }
}

.product-name {
  width: 100%;

  @media (--small) {
    place-self: flex-start;
    max-width: 130%;
  }
}

.swatches {
  order: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;

  & .plus {
    font-size: var(--font-size-2xs);
    padding-left: 0.35rem;
    padding-right: 0.15rem;
  }

  & .more-swatches {
    font-size: var(--font-size-2xs);
  }

  @media (--small) {
    order: unset;
    place-self: center;
    justify-content: center;
    padding-top: 0;
    & .more-swatches {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  @container product-card (max-width: 300px) {
    order: 3;
    place-self: flex-start;
    justify-content: flex-start;
    padding-top: 2px;
    & .more-swatches {
      text-decoration: none;
    }
  }
}

.swatch {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
}

.product-price {
  place-self: flex-start;
  @media (--small) {
    place-self: end;
    @container product-card (max-width: 300px) {
      place-self: flex-start;
    }
  }
}

.pc-button {
  position: absolute;
  inset: 0;
  top: auto;
  bottom: var(--distance-from-bottom);
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px !important;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  z-index: 15;
  background-color: var(--color-white);
  transition: all 0.3s ease;
  opacity: 0;
  &:hover {
    opacity: 0;
  }
}

.action {
  position: absolute !important;
  inset: 0;
  margin-inline: 2rem;
  padding-block: 1rem;
  bottom: 5%;
  top: auto;
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  min-height: 64px;
  z-index: 20;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  align-items: center;
  gap: 0.5rem;
  & > * > svg path {
    stroke: var(--color-primary);
  }

  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  transform: scale(0.9);
  display: flex;
  pointer-events: none;
  opacity: 0;

  &:hover,
  &.show {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

.actions-wrapper {
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: var(--gap);
  flex-wrap: wrap;
  color: var(--color-primary);
  transition: opacity 0.15s ease;
}

.size-buttons {
  cursor: pointer;
  transition: all 0.15s ease;
  text-decoration-color: transparent;
  &:hover {
    font-weight: 500;
  }
  &.selected {
    cursor: default;
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: var(--color-primary);
    text-decoration-thickness: 2px;
  }
  &:disabled {
    opacity: 1;
    color: var(--color-primary);
    font-weight: 800;
  }
}

.wishlist-icon {
  display: block;
  z-index: 20;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;

  z-index: 10;
  cursor: pointer;
  transition: all 0.15s ease;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

.apply__to__cart {
  margin-inline: auto;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.card__tag {
  @extend %typo-tag1;
  position: absolute;
  isolation: isolate;

  inset: 0;
  top: 1.5rem;
  bottom: auto;
  width: 100%;

  text-align: center;
  & > * {
    margin-inline: auto;
    width: max-content;
    padding: 0.5rem 1rem;
    background: var(--color-white);
    border-radius: 500px;
  }

  & > p {
    font-family: var(--font-haas-grot-display);
  }
  &:has(+ .sale__tag) {
    top: 2.75rem;
  }

  &:has(+ .black-tag) {
    top: 2.75rem;
  }

  @media (max-width: 319px) {
    & > * {
      font-size: 3.75vw !important;
      padding: 1vw 2vw;
    }
  }
}

.sale__tag {
  color: var(--tag-color, var(--color-white));
  top: 0.5rem;
  & > * {
    background-color: var(--tag-bg, var(--color-accent));
  }
}

.black-tag {
  top: 0.5rem;
  color: var(--color-primary);
}

.swatch__tooltip {
  display: flex;
  flex-direction: row;
  max-width: 5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & > div {
    border-color: var(--color-primary);
  }
}
