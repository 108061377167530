.root {
  display: flex;
  flex-direction: column-reverse;
  @media (--large) {
    flex-direction: row;
    &.left__side {
      flex-direction: row-reverse;
    }
  }
}

.content__container {
  --content-padding: 3rem;
  padding-block: var(--content-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--large) {
    padding-inline: var(--page-inset);
    padding-block: 6.25rem;
    flex-grow: 1;
    justify-content: center;
    width: 50%;
    overflow: hidden;
  }
}

.content__item {
  overflow: hidden;
  width: 100%;
  @media (--large) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & > * {
    width: 100%;
  }
}

.hero__container {
  @media (--large) {
    position: relative;
    flex-grow: 1;
    width: 50%;
  }
}

.hero__item {
  height: 100vh;
  @media (--large) {
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
    height: 100%;
    height: calc(100vh - var(--header-height));
  }
}
