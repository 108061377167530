.trigger {
  font-size: var(--font-size-fixed-2xs);
  letter-spacing: 0.08em;
  font-weight: 400;
}

.content {
  overflow: hidden;


  & > p {
    font-size: var(--font-size-2xs);
  }

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
