.container {
  container-name: mediaGallery;
  container-type: inline-size;
}
.root {
  @media (--large) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    padding-inline: 11.75vw;
    column-gap: 2rem;
    /* min-height: 75vh; */
    & {
      @container (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 6rem;
      }
    }
  }
  &.server__root {
    @media (--mobile-only) {
      padding-inline: var(--page-inset);
      padding-bottom: 1rem;
      display: flex;
      flex-direction: row;
      gap: 6px;
      overflow: scroll;
      & > a {
        min-width: 90vw;
      }
      &[data-single-item="true"] {
        width: calc(100% - (2 * var(--page-inset)));
      }
    }
    @media (--large) {
      & > a:nth-child(3) ~ a {
        display: none;
      }
    }
  }
}

.slider__on__desktop {
  & .container[data-server="true"] {
    display: block;
  }
  & .container[data-server="false"] {
    display: none;
  }

  @media (--large) {
    & .container[data-server="true"] {
      display: none;
    }
    & .container[data-server="false"] {
      display: block;
    }
  }
}

.card {
  position: relative;
}
