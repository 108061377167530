.arrow {
  fill: var(--color-white);
  filter: drop-shadow(0px 0px 10px rgba(71, 71, 68, 0.8));
}

.content {
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 15px;
  line-height: 1;
  color: var(--color-primary);
  background-color: var(--color-white);
  box-shadow: 0px 0px 10px rgba(184, 183, 177, 0.5);
  user-select: none;
  animation-duration: 100ms;
  animation-timing-function: ease;
  will-change: transform, opacity;
  position: relative;
  z-index: 999;
}

.content[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.content[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.content[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.content[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
