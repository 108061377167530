.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  & > * {
    cursor: pointer;
  }
  &:hover .input {
    border-width: 2px;
  }
}

.label {
  cursor: pointer;
}

.input {
  cursor: pointer;
  background-color: var(--color-background);
  margin: 0;
  display: grid;
  place-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--color-primary);

  &::before {
    content: "";
    cursor: pointer;
    width: 20px;
    height: 20px;
    opacity: 0;
    border-radius: 4px;
    transition: 200ms opacity ease;
    background-color: var(--color-primary);
    background-image: url("/Check.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
  }

  &:checked::before {
    opacity: 1;
  }
}
