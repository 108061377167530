.component-showcase {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  &.vertical {
    flex-direction: column;
  }
}

.kitchen-sink {
  & .heading + .subheading {
    margin-top: var(--content-margin);
  }

  & .heading {
    font-size: var(--font-size-xl);
    font-weight: 600;
    margin-top: 72px;
    margin-bottom: 36px;
    text-align: left;
  }

  & .subheading {
    margin-top: 40px;
    font-size: var(--font-size-l);
    border-bottom: 2px solid var(--color-primary-20);
    margin-bottom: 24px;
    padding-bottom: 8px;
    display: flex;
    align-items: flex-end;
    font-weight: 400;
    gap: 20px;
    width: 100%;

    & span {
      font-size: var(--font-size-2xs);
      font-weight: 400;
      background: var(--color-primary-10);
      padding: 4px 6px;
      border-radius: 4px;
    }
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
