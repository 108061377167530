.modal {
  --modal-height: 200px;
  position: fixed;
  inset: 0;
  pointer-events: none;
  width: 100%;
  height: var(--modal-height);
  top: 2em;

  z-index: 1;
  opacity: 0;

  &.active {
    pointer-events: all;
    opacity: 1;
    z-index: 3;
  }

  @media (--large) {
    top: 67px;
  }
}
& .modal__content {
  z-index: 3;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background);
  padding-top: 12px;
  border-block: 1px solid var(--color-primary);
}

.modal__header {
  padding: 0 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0;
    font-family: var(--font-haas-grot-display) !important;
    font-size: var(--font-size-menu-item) !important;
  }
  p {
    font-family: var(--font-haas-grot-display) !important;
    font-size: var(--font-size-menu-item) !important;
  }
}

.sort-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  width: 100%;
  padding-top: 2rem;

  @media (--small) {
    padding-top: 1rem;
  }
}

.sort-option {
  cursor: pointer;
  transition: all 0.15s ease;

  &:hover {
    font-weight: 600;
    scale: 1.01;
  }

  &.active {
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  &:disabled {
    color: var(--color-black);
    cursor: default;
    &:hover {
      font-weight: 600;
      scale: 1;
    }
  }
}

/* .overlay {
  position: absolute;
  top: 50px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  left: 0;
  right: 0;
  display: none;
  opacity: 0;
  transition: all 0.15s ease;
  &.active {
    opacity: 1;
    display: block;
  }
} */
