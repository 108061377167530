.root {
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding-inline: var(--page-inset);
  &[data-state="open"] {
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    animation: slideDown 300ms ease-in-out;
  }
  &[data-state="closed"] {
    animation: slideUp 300ms ease-in-out;
  }
}

.content {
  max-width: 100%;
  padding-block: 0.5rem;

  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  justify-self: flex-start;
  margin-bottom: 0.5rem;

  &::-webkit-scrollbar {
    display: none; /* Safari, Chrome & Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
