.discounted__wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.375rem;
  align-content: flex-end;
}

.price__before__discount {
  place-self: flex-end;
  font-size: var(--font-size-2xs);
  & .discount__number {
    text-decoration: line-through;
  }
}

.price__after__discount {
  color: var(--color-accent);
}
