.root {
  display: grid;
  @media (--large) {
    grid-template-columns: 1fr 3fr;
  }
}

.left {
  height: 100%;
  position: relative;
}

.details {
  --inset-left: 1.5rem;
  --max-width: 90%;
  padding-bottom: 2rem;

  @media (--large) {
    --inset-left: 0;
    --sticky-start-position: calc(var(--header-height) + 6.5rem);
    --max-width: 100%;
    --inset-bottom: 2.5rem;
    --inset-top: 1rem;
    aspect-ratio: 2/3.125; /* 2/3 from productcard image + 0.125 for the text */
    position: sticky;
    top: var(--sticky-start-position);
    padding-bottom: var(--inset-bottom);
    padding-top: var(--inset-top);

    & a {
      margin-left: -6px; /* make up for padding so it aligns with the content but keeps the click area. */
    }
  }

  /* shared */
  display: flex;
  flex-direction: column;
  place-items: flex-start;
  justify-content: flex-start;
  max-width: var(--max-width);
  padding-left: var(--inset-left);
}

.title {
  @extend %typo-h3;
  font-weight: 400; /* design inconsistent with tokens */
  font-size: var(--font-size-38); /* design inconsistent with tokens */
  margin-bottom: 2rem;
  @media (--large) {
    flex-grow: 1;
  }
}

.description {
  @extend %typo-b2;
  line-height: 1.4375; /* design inconsistent with tokens - mobile font size 16px line height 23px. */
  margin-bottom: 2rem;
  @media (--large) {
    max-width: 65%;
    font-size: clamp(
      1.125rem,
      1vw,
      1.5rem
    ); /* design inconsistent with tokens - token size grows too fast compared to design */
  }
}

.right {
  max-width: 100%;
  overflow: auto;
}

.product__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.25rem;
  row-gap: 7.5rem;

  @media (--mobile-only) {
    display: flex;
    padding-bottom: 1rem;
    & > * {
      min-width: 85vw !important;
    }
  }
}
