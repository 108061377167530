.search-form {
  --transition: 200ms;
  --input-font-size: var(--font-size-s);
  --input-padding-v: 0.5rem;
  --input-padding-h: 0rem;
  --input-br: 0px;
  --menu-height: min(20rem, 100vh);

  position: fixed;
  max-height: 100vh;
  inset: 0;
  top: var(--header-height);
  bottom: auto;
  display: block;
  background: var(--color-background);
  padding-top: 2rem;
  border-bottom: 1px solid var(--color-primary);
  transform: translateY(calc(-20rem));
  padding-bottom: 2rem;
  height: fit-content;
  z-index: 10;
  /*   max-height: 6.25rem; */
  overflow: auto;

  &:has(.search__result__item) {
    max-height: var(--menu-height);
    overflow-y: auto;
  }
  @media (--large) {
    --input-font-size: var(--font-size-xs);
    --input-padding-v: 0.5rem;
    --input-padding-h: 0;
  }

  &::backdrop {
    background: rgb(255 0 0 / 25%);
    animation: fadeIn 0.3s ease forwards;
    background-color: red;
  }

  &[data-state="open"] {
    display: block;
    animation: slideIn 0.3s ease forwards;
    transform: translateY(0px);
  }

  & button > svg path {
    stroke-width: 1px;
    stroke: var(--color-primary);
    color: var(--color-primary);
  }

  & input {
    background: inherit;
    box-sizing: border-box;
    padding: var(--input-padding-v) var(--input-padding-h);
    border: 0;
    border-bottom: 1px solid var(--color-primary);
    width: 100%;
    font-size: var(--input-font-size);
    transition: all var(--transition);
    line-height: var(--line-height);

    &:hover {
      &::placeholder {
        opacity: 0.6;
        transform: translateY(-2px);
      }
    }

    &::placeholder {
      /* Not currently in use */
      transition: all 0.1s ease;
      color: var(--color-primary);
      font-size: var(--font-size-xs);
      opacity: 1;
    }

    &:focus {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.02);
    }
    &:focus-visible {
      outline: 0;
    }

    &[data-state="open"] {
      padding: calc(var(--input-padding-v) + 4px) var(--input-padding-h)
        calc(var(--input-padding-v) - 4px);

      & + label {
        font-size: 10px;
        top: 10px;
        line-height: 1;
      }
    }
  }

  & label {
    position: absolute;
    left: var(--input-padding-h);
    top: var(--input-padding-v);
    font-size: var(--input-font-size);
    color: var(--color-primary-60);
    pointer-events: none;
    transition: all var(--transition);
    line-height: var(--line-height);
  }

  & .searchButton {
    position: absolute;
    inset: auto;
    right: 0;
    top: 5px;
    padding: 0 var(--input-padding-h);
    background: transparent;
    border: none;
    border-radius: var(--input-br);
    cursor: pointer;
  }
}

.search-button {
  & svg {
    transition: all 0.2s ease-in-out;
  }
  /* &:hover svg {
    opacity: 0.8;
  } */
}

.input__element {
  &:hover::placeholder {
    opacity: 0% !important;
  }
  &::placeholder {
    color: var(--color-primary);
    font-size: var(--font-size-3xs);
    opacity: 70% !important;
  }
}

.search__result {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  padding-top: 2rem;
  gap: 1rem;
  max-width: min(350px, 95vw);
}

.search__result__item {
  font-size: var(--font-size-xs);
  width: max-content;
  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 1px;
    margin-top: 2px;
    background: var(--color-primary);
    transition: width 0.2s ease-in-out;
  }
  &:hover::after {
    width: 100%;
  }
}
.searchResultWrapper {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (--medium) {
    grid-template-columns: 30% 70%;
  }
}

.page {
  display: flex;
  position: relative;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.productsFooter {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogOverlay {
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.keyword {
  @extend %typo-menu-link1;
}
