.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: clamp(8px, 0.585vw, 16px);
  grid-row-gap: clamp(40px, 8.78vw, 60px);

  & > :global(.product-card) {
    grid-column: span 2;
    @media (--large) {
      grid-column: span 1;
    }
  }

  @media (--large) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: clamp(120px, 8.78vw, 240px);
  }
}
