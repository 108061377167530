.modal__title {
  @extend %typo-menu-link1;
  text-transform: capitalize;
}

.modal__t2 {
  font-family: var(--font-century-gothic) !important;
  font-size: var(--font-size-fixed-m) !important;
  text-transform: capitalize;
}

.measurementSection {
  margin-top: 5rem;
}

.preambleTitle {
  font-family: var(--font-century-gothic);
  font-size: var(--font-size-fixed-m);
  text-transform: uppercase;
  font-weight: var(--typo-weight);
  letter-spacing: 0;
  line-height: 1.2;
  padding: 10px 0;
  text-align: left;
  margin-top: 5rem;
}

.preamble {
  @extend %typo-b3;
}

.sizeguideLink {
  text-decoration: underline;
  @media (--large) {
    &:hover {
      position: unset;
      transition: none;
      text-decoration: none;
    }

    &::after {
      display: none;
    }
  }
}

.sizeguide-modal {
  z-index: 920;
  width: min(100%, 32.5rem);
  background-color: var(--color-background);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: auto;
  padding-bottom: 10rem;
}

.meassure-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 5rem;

  & > tbody > tr {
    & > td {
      @extend %typo-b3;
      padding: 8px 0;
    }
  }
  & > thead {
    & > th {
      text-align: left;
      padding: 10px 0;
      @extend %typo-h5;
    }
  }
}
.sub-title {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  @extend %typo-h5;
}

.description {
  @extend %typo-b3;
  padding-top: 2rem;
}
