.wishlistButton {
  position: relative;
  --svg-color: var(--color-black);
  opacity: 1;
  &:hover {
    --heart-fill: var(--color-black);
    & .animation {
      opacity: 1;
    }
  }
}

.animation {
  @media (--large) {
    margin-left: 0.5rem;
  }
}
