.grid {
  width: 100%;
  overflow: auto;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (--large) {
    overflow: visible;
    margin-top: var(--header-height);

    gap: 0.5rem;
  }
}

.item {
  width: 75vw;

  transition: flex-grow 0.5s ease;

  @media (--large) {
    width: auto;
    min-height: clamp(30vh, 30vw, 50vh);
    flex-grow: 1;
    &:hover {
      flex-grow: 4;
    }
  }
}

.grid:not(:has(.item:hover)) .item:last-child {
  @media (--large) {
    flex-grow: 4;
  }
}

.gallery {
  position: relative;
  width: inherit;
  padding-bottom: 125%;
  @media (--large) {
    padding: 0;
    height: 100%;

    & .image {
      transition: all 0.5s ease;
      object-fit: cover;
    }
  }
}

.title {
  padding-top: 0.5rem;
  font-size: 16px;
}
